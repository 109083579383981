import { ThunkAction } from '@reduxjs/toolkit'
import { GraphQueryArgs, LineGraphData } from 'interfaces/graphApi'
import { RootState } from 'store'
import { inTimeGraphApi } from 'store/apis/inTimeGraphApi'

export const selectTorqueInRangeInTimeByArgs = (
  args: GraphQueryArgs
): ThunkAction<LineGraphData[] | undefined, RootState, unknown, any> => {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState()
    const queryState = inTimeGraphApi.endpoints.getTorqueInRangeInTime.select(args)(state)
    const data: LineGraphData[] | undefined = queryState.data
    return data
  }
}
