import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { ExportQueryArgs } from 'interfaces/exportApi'

const exportApi = createApi({
  reducerPath: 'exportApi',
  baseQuery: fakeBaseQuery(),
  endpoints(build) {
    return {
      exportVesselData: build.query<string, ExportQueryArgs>({
        keepUnusedDataFor: 3600, // 1 hour
        queryFn: async ({ companyId, vesselId, startTime, endTime, format }: ExportQueryArgs) => {
          const exportVesselData = httpsCallable(functions, 'exportVesselData')
          const result = await exportVesselData({ companyId, vesselId, startTime, endTime, format })
          const data: any = result
          return data
        },
      }),
    }
  },
})

export const { useLazyExportVesselDataQuery } = exportApi
export { exportApi }
