import ReactDatePicker from 'react-datepicker'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { format, parse, subDays } from 'date-fns'
import classNames from 'classnames'
import ReusableInputField from '../Input'
import { DDMMYYYY_WITH_SPACES } from '../../shared/validation/regex-patterns'
import Button from 'components/Button'

interface Props {
  onCancel: () => void
  onApply: ({ startTime, endTime }: { startTime: Date | null; endTime: Date | null }) => void
  initialStartDate?: Date
  initialEndDate?: Date
}

function DatePicker({ onCancel, onApply, initialStartDate, initialEndDate }: Props) {
  const currentDate = new Date()
  const [startDate, setStartDate] = useState<Date | null>(initialStartDate || null)
  const [endDate, setEndDate] = useState<Date | null>(initialEndDate || null)
  const [formattedStartDate, setFormattedStartDate] = useState<string>('')
  const [formattedEndDate, setFormattedEndDate] = useState<string>('')
  const [startDateError, setStartDateError] = useState<boolean>(false)
  const [endDateError, setEndDateError] = useState<boolean>(false)

  const handleChangeStartDate = (newStartDate: Date | null) => {
    setStartDate(newStartDate)
  }

  const handleChangeEndDate = (newEndDate: Date | null) => {
    setEndDate(newEndDate)
  }

  useEffect(() => {
    if (startDate) setFormattedStartDate(format(startDate, 'dd / MM / y'))
    else setFormattedStartDate('')

    if (startDate && endDate && startDate > endDate) setEndDate(startDate)
  }, [startDate])

  useEffect(() => {
    if (endDate) setFormattedEndDate(format(endDate, 'dd / MM / y'))
    else setFormattedEndDate('')

    if (startDate && endDate && startDate > endDate) setStartDate(endDate)
  }, [endDate])

  function handleStartInputChange(value: string) {
    const cleanValue = value.replaceAll(' ', '')
    const parsedValue = parse(cleanValue, 'dd/MM/y', new Date())
    setFormattedStartDate(value)
    if (!DDMMYYYY_WITH_SPACES.test(value.trim()) || parsedValue.toString() === 'Invalid Date') {
      setStartDateError(true)
    } else {
      setStartDateError(false)
      setStartDate(parsedValue)
    }
  }

  function handleEndInputChange(value: string) {
    const cleanValue = value.replaceAll(' ', '')
    const parsedValue = parse(cleanValue, 'dd/MM/y', new Date())
    setFormattedEndDate(value)
    if (!DDMMYYYY_WITH_SPACES.test(value.trim()) || parsedValue.toString() === 'Invalid Date') {
      setEndDateError(true)
    } else {
      setEndDateError(false)
      setEndDate(parsedValue)
    }
  }

  function handleCancelClick() {
    onCancel()
  }

  function handleApplyClick() {
    onApply({ startTime: startDate, endTime: endDate })
  }

  const buttonCssClasses = 'inline-flex bg-blue p-1 text-sm font-medium bg-white outline-none text-grayPlaceholder'

  return (
    <div className="bg-white shadow-[0_0_10px_3px_rgba(0,0,0,0.07)] rounded-lg">
      <div className="flex">
        <ReactDatePicker
          selected={startDate}
          onChange={handleChangeStartDate}
          startDate={startDate}
          monthsShown={1}
          calendarStartDay={1}
          selectsRange={false}
          inline
          renderCustomHeader={({
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            monthDate,
          }) => {
            return (
              <div className="flex flex-col items-center justify-between px-4 py-4 border-b border-grayOutlineColor">
                <div className="flex">Start date</div>
                <div className="flex items-center">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    type="button"
                    className={classNames(buttonCssClasses, {
                      'cursor-not-allowed opacity-50': prevMonthButtonDisabled,
                    })}
                  >
                    <FontAwesomeIcon
                      className="px-3 py-2"
                      icon={faChevronLeft}
                    />
                  </button>

                  <span className="text-lg">{format(monthDate, 'MMMM yyyy')}</span>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    type="button"
                    className={classNames(buttonCssClasses, {
                      'cursor-not-allowed opacity-50': nextMonthButtonDisabled,
                    })}
                  >
                    <FontAwesomeIcon
                      className="px-3 py-2"
                      icon={faChevronRight}
                    />
                  </button>
                </div>
              </div>
            )
          }}
        />
        <ReactDatePicker
          selected={endDate}
          onChange={handleChangeEndDate}
          startDate={endDate}
          monthsShown={1}
          calendarStartDay={1}
          selectsRange={false}
          inline
          renderCustomHeader={({
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
            monthDate,
          }) => {
            return (
              <div className="flex flex-col items-center justify-between px-4 py-4 border-b border-grayOutlineColor">
                <div className="flex">End date</div>
                <div className="flex items-center">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    type="button"
                    className={classNames(buttonCssClasses, {
                      'cursor-not-allowed opacity-50': prevMonthButtonDisabled,
                    })}
                  >
                    <FontAwesomeIcon
                      className="px-3 py-2"
                      icon={faChevronLeft}
                    />
                  </button>

                  <span className="text-lg">{format(monthDate, 'MMMM yyyy')}</span>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    type="button"
                    className={classNames(buttonCssClasses, {
                      'cursor-not-allowed opacity-50': nextMonthButtonDisabled,
                    })}
                  >
                    <FontAwesomeIcon
                      className="px-3 py-2"
                      icon={faChevronRight}
                    />
                  </button>
                </div>
              </div>
            )
          }}
        />
      </div>
      <div className="flex border-t border-grayOutlineColor">
        <div className="p-4 w-1/2 flex">
          <ReusableInputField
            value={formattedStartDate}
            onChange={handleStartInputChange}
            className="text-sm"
            error={startDateError}
          />
          <div className="p-2">To</div>
          <ReusableInputField
            value={formattedEndDate}
            onChange={handleEndInputChange}
            className="text-sm"
            error={endDateError}
          />
        </div>
        <div className="flex justify-between p-4 w-1/2">
          <div className="w-[47%]">
            <Button
              onClick={handleCancelClick}
              primary
              fullWidth
            >
              Cancel
            </Button>
          </div>
          <div className="w-[47%]">
            <Button
              onClick={handleApplyClick}
              secondary
              fullWidth
              disabled={startDate === null || endDate === null}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DatePicker
