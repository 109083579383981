import { useNavigate } from 'react-router-dom'
import Logo from 'assets/images/Periscope_Logo.svg'
import HelpIcon from 'assets/help_icon.png'
import Select, { SelectOption } from 'components/form/Select'
import { useTimezoneSelect, allTimezones, ITimezoneOption } from 'react-timezone-select'
import { useDispatch } from 'react-redux'
import { changeTimezoneOffset, RootState } from 'store'
import { useAppSelector } from 'hooks/useReduxHooks'

function MainHeader() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleButtonClick = (route: string) => {
    navigate(route)
  }
  const {
    app: { selectedTimezone },
    auth: { user },
  } = useAppSelector((state: RootState) => {
    return state
  })

  const showTimeZoneSelect = user?.email === 'tests@gmail.com'

  const labelStyle = 'original'
  const timezones = {
    ...allTimezones,
  }

  const { options: timezoneOptions, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

  function handleTimezoneChange(selectedOptions: ITimezoneOption[] | ITimezoneOption) {
    const newValues = selectedOptions.constructor === Array ? selectedOptions : [selectedOptions]
    const newTimezone = newValues[0]
    dispatch(changeTimezoneOffset(newTimezone))
  }

  return (
    <div className="header w-screen justify-between h-20 bg-white flex border-b border-grayOutlineColor">
      <img
        src={Logo}
        className="h-8 m-auto mx-4 cursor-pointer"
        alt="Ship Project Logo"
        onClick={() => handleButtonClick('/')}
      />

      <div className="flex items-center h-full mx-4 gap-6">
        <img
          src={HelpIcon}
          className="w-5 h-auto cursor-pointer"
          onClick={() => handleButtonClick('/help-centre')}
        ></img>

        {/* <div className="w-12 h-12 bg-accentColor rounded-full">
          <p className="flex items-center justify-center h-full text-black font-semibold text-xl">JD</p>
        </div> */}
      </div>
    </div>
  )
}

export default MainHeader
