import { createSlice } from '@reduxjs/toolkit'
import { VesselData } from 'interfaces/vessel'

interface PerformanceState {
  selectedVessel: VesselData | null
  vesselIsFetching: boolean
  dateRange: {
    startTime: number
    endTime: number
  }
}

const initialState: PerformanceState = {
  selectedVessel: null,
  vesselIsFetching: false,
  dateRange: {
    startTime: 0,
    endTime: 0,
  },
}

const performanceSlice = createSlice({
  name: 'performance',
  initialState,
  reducers: {
    changeSelectedVessel(state, action) {
      state.selectedVessel = action.payload
    },
    changeVesselIsFetching(state, action) {
      state.vesselIsFetching = action.payload
    },
    changeDateRange(state, action) {
      state.dateRange = action.payload
    },
  },
})

export const performanceReducer = performanceSlice.reducer
export const { changeSelectedVessel, changeVesselIsFetching, changeDateRange } = performanceSlice.actions
