import { RootState } from 'store'
import TotalFuelConsumed from '../../../components/performance-overview/TotalFuelConsumed'
import { useAppSelector } from 'hooks/useReduxHooks'
import TotalCO2Emitted from '../../../components/performance-overview/TotalCO2Emitted'
import VesselInfo from 'components/VesselInfo'

function Overview() {
  const {
    dateRange: { startTime, endTime },
    selectedVessel,
    vesselIsFetching,
  } = useAppSelector((state: RootState) => {
    return state.performance
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {startTime && endTime && (
          <div className="mb-8">
            <TotalFuelConsumed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}

        {startTime && endTime && (
          <TotalCO2Emitted
            vessel={selectedVessel || null}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </div>
    </div>
  )
}

export default Overview
