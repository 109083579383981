import classNames from 'classnames'
import FormGroup from 'components/form/FormGroup'
import FormLabel from 'components/form/FormLabel'
import RadioButtonGroup from 'components/form/RadioButtonGroup'
import Select, { SelectOption } from 'components/form/Select'
import ToggleSwitch from 'components/form/ToggleSwitch'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  EngineOption,
  analyticsState,
  changeGraph2Source,
  changeGraph2Metric,
  changeGraph2Engines,
  changeGraph2TrendlineStatus,
  changeGraph1Metric,
} from 'store/slices/analyticsSlice'

interface Props {
  sourceOptions: any[]
  engineOptions: any[]
}

function FiltersPanelBlock2({ sourceOptions, engineOptions }: Props) {
  const dispatch = useDispatch()
  const { measuredBy, graph1Filters, graph2Filters, allowTrendline } = useSelector(analyticsState)
  const block2Disabled = graph1Filters.metric?.value === 'fuelConsumed'
  const sourceTwoInitialEngine: SelectOption[] = []

  useEffect(() => {
    resetSource2Engine()
  }, [graph2Filters.source])

  useEffect(() => {
    resetSource2Engine()
  }, [measuredBy])

  function resetSource2Engine() {
    dispatch(changeGraph2Metric(undefined))

    const mainEngine = engineOptions.find(({ type }: EngineOption) => type === 'm/e')
    const auxEngine = engineOptions.find(({ type }: EngineOption) => type === 'a/e')
    if (auxEngine) {
      sourceTwoInitialEngine.push(auxEngine)
      dispatch(changeGraph2Engines([auxEngine]))
    } else {
      sourceTwoInitialEngine.push(mainEngine)
      dispatch(changeGraph2Engines([mainEngine]))
    }
  }

  function handleSourceTwoChange(value: string) {
    dispatch(changeGraph2Source(value))
  }

  function handleEnginesTwoChange(selectedOptions: SelectOption[]) {
    const newValues = selectedOptions.constructor === Array ? selectedOptions : [selectedOptions]
    dispatch(changeGraph2Engines(newValues))
  }

  function handleMetricTwoChange(selectedOption: any) {
    if (selectedOption?.value === 'fuelConsumed') {
      dispatch(changeGraph1Metric(null))
    }
    dispatch(changeGraph2Metric(selectedOption))
  }

  function handleTrendlineTwoToggle(value: boolean) {
    dispatch(changeGraph2TrendlineStatus(value))
  }

  return (
    <div className="flex-1 filter-block p-4 pb-0 border-grayOutlineColor relative">
      {block2Disabled && <div className="absolute z-10 w-full h-full bg-white top-0 left-0 opacity-75"></div>}
      <header className="filter-block-header flex p-1 leading-18 mb-4">
        <div className="h-[1.125rem] w-[1.125rem] mr-[0.45rem] bg-[#22A73F] text-white text-[0.875rem] text-center">
          2
        </div>
        <div className="text-sm lg:text-lg">Data source</div>
      </header>
      <div className="Ffilter-block-content ">
        <FormGroup className="flex justify-between">
          <RadioButtonGroup
            options={sourceOptions}
            onChange={handleSourceTwoChange}
            initialValue={sourceOptions[0].name}
            inline
            hideCheck
            className="text-bold text-center"
            textCenter
            textStrong
          />
        </FormGroup>
        {graph2Filters.source === 'engines' && (
          <FormGroup>
            <FormLabel>Selected engines</FormLabel>
            <Select
              options={engineOptions}
              placeholder="Select an engine"
              onChange={handleEnginesTwoChange}
              defaultValue={sourceTwoInitialEngine}
              value={graph2Filters.selectedEngines}
              isMulti={measuredBy === 'time'}
            />
          </FormGroup>
        )}
        <FormGroup>
          <FormLabel>Metrics</FormLabel>
          <Select
            options={graph2Filters.metricOptions}
            onChange={handleMetricTwoChange}
            value={graph2Filters.metric || ''}
            placeholder="Select a metric"
            disabled={graph2Filters.metricOptions.length === 0}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <ToggleSwitch
            size="sm"
            value={graph2Filters.drawTrendline}
            onChange={handleTrendlineTwoToggle}
            disabled={!allowTrendline}
            label="Draw trendline"
            className={classNames('items-center text-lg font-extrabold', { 'opacity-30': !allowTrendline })}
          />
        </FormGroup>
        {/* <FormGroup>
        <ToggleSwitch
          size="sm"
          value={''}
          onChange={() => {}}
          label="Toggle to save your current selections as the default
          settings for future reports."
          className="items-center text-[0.75rem] leading-3 text-grayText"
        />
      </FormGroup> */}
      </div>
    </div>
  )
}

export default FiltersPanelBlock2
