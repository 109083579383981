import { SourceFilters } from '../../../store/slices/analyticsSlice'
import { SplitSensor, VesselData } from 'interfaces/vessel'

import { RootState, changeGraphIsFetching, changeChartIsRendering } from '../../../store'

import { GraphQueryArgs } from 'interfaces/graphApi'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { useEffect, useRef, useState } from 'react'
import AnalyticsChart from './AnalyticsChart'
import { areObjectsEqual } from 'shared/objects/areObjectsEqual'

import { fetchMassFlowRateInRangeInTime } from 'store/thunks/inTimeGraphData/fetchMassFlowRateInRangeInTime'
import { selectMassFlowRateInRangeInTimeByArgs } from 'store/actions/inTimeGraphData/selectMassFlowRateInRangeInTimeByArgs'
import { selectTotalFuelConsumedBarChartInRangeByArgs } from 'store/actions/totalsGraphData/selectTotalFuelConsumedBarChartInRangeByArgs'
import { fetchTotalFuelConsumedBarChartInRange } from 'store/thunks/totalsGraphData/fetchTotalFuelConsumedBarChartInRange'
import { selectPowerInRangeInTimeByArgs } from 'store/actions/inTimeGraphData/selectPowerInRangeInTimeByArgs'
import { fetchPowerInRangeInTime } from 'store/thunks/inTimeGraphData/fetchPowerInRangeInTime'
import { selectTorqueInRangeInTimeByArgs } from 'store/actions/inTimeGraphData/selectTorqueInRangeInTimeByArgs'
import { fetchTorqueInRangeInTime } from 'store/thunks/inTimeGraphData/fetchTorqueInRangeInTime'
import { selectRPMInRangeInTimeByArgs } from 'store/actions/inTimeGraphData/selectRPMInRangeInTimeByArgs'
import { fetchRPMInRangeInTime } from 'store/thunks/inTimeGraphData/fetchRPMInRangeInTime'
import { selectTotalFuelEfficiencyInTimeByArgs } from 'store/actions/inTimeGraphData/selectTotalFuelEfficiencyInTimeByArgs'
import { fetchTotalFuelEfficiencyInTime } from 'store/thunks/inTimeGraphData/fetchTotalFuelEfficiencyInTime'
import { selectSFOCInTimeByArgs } from 'store/actions/inTimeGraphData/selectSFOCInTimeByArgs'
import { fetchSFOCInTime } from 'store/thunks/inTimeGraphData/fetchSFOCInTime'
import { selectSOGInRangeInTimeByArgs } from 'store/actions/inTimeGraphData/selectSOGInRangeInTimeByArgs'
import { fetchSOGInRangeInTime } from 'store/thunks/inTimeGraphData/fetchSOGInRangeInTime'
import { selectFuelEfficiencyInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectFuelEfficiencyInKnotsByArgs'
import { fetchFuelEfficiencyInKnots } from 'store/thunks/inKnotsGraphData/fetchFuelEfficiencyInKnots'
import { selectPowerInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectPowerInKnotsByArgs'
import { fetchPowerInKnots } from 'store/thunks/inKnotsGraphData/fetchPowerInKnots'
import { selectTorqueInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectTorqueInKnotsByArgs'
import { fetchTorqueInKnots } from 'store/thunks/inKnotsGraphData/fetchTorqueInKnots'
import { selectRPMInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectRPMInKnotsByArgs'
import { fetchRPMInKnots } from 'store/thunks/inKnotsGraphData/fetchRPMInKnots'
import { selectMassFlowRateInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectMassFlowRateInKnotsByArgs'
import { fetchMassFlowRateInKnots } from 'store/thunks/inKnotsGraphData/fetchMassFlowRateInKnots'
import { selectSFOCInKnotsByArgs } from 'store/actions/inKnotsGraphData/selectSFOCInKnotsByArgs'
import { fetchSFOCInKnots } from 'store/thunks/inKnotsGraphData/fetchSFOCInKnots'
import { selectFuelEfficiencyInPowerByArgs } from 'store/actions/inPowerGraphData/selectFuelEfficiencyInPowerByArgs'
import { fetchFuelEfficiencyInPower } from 'store/thunks/inPowerGraphData/fetchFuelEfficiencyInPower'
import { selectSFOCInPowerByArgs } from 'store/actions/inPowerGraphData/selectSFOCInPowerByArgs'
import { fetchSFOCInPower } from 'store/thunks/inPowerGraphData/fetchSFOCInPower'
import { selectTorqueInPowerByArgs } from 'store/actions/inPowerGraphData/selectTorqueInPowerByArgs'
import { fetchTorqueInPower } from 'store/thunks/inPowerGraphData/fetchTorqueInPower'
import { selectRPMInPowerByArgs } from 'store/actions/inPowerGraphData/selectRPMInPowerByArgs'
import { fetchRPMInPower } from 'store/thunks/inPowerGraphData/fetchRPMInPower'
import { selectMassFlowRateInPowerByArgs } from 'store/actions/inPowerGraphData/selectMassFlowRateInPowerByArgs'
import { fetchMassFlowRateInPower } from 'store/thunks/inPowerGraphData/fetchMassFlowRateInPower'
import { selectSOGInPowerByArgs } from 'store/actions/inPowerGraphData/selectSOGInPowerByArgs'
import { fetchSOGInPower } from 'store/thunks/inPowerGraphData/fetchSOGInPower'

interface Props {
  vessel: VesselData
}

function AnalyticsChartLoader({ vessel }: Props) {
  const dispatch = useAppDispatch()
  const { graph1Filters, graph2Filters, measuredBy, dateFilters } = useAppSelector((state: RootState) => {
    return state.analytics
  })
  const [previousGraph1Filters, setPreviousGraph1Filters] = useState<SourceFilters>()
  const [previousGraph2Filters, setPreviousGraph2Filters] = useState<SourceFilters>()
  const [previousMeasuredBy, setPreviousMeasuredBy] = useState<string>()
  const [previousGraph1QueryParams, setPreviousGraph1QueryParams] = useState<GraphQueryArgs>()
  const [previousGraph2QueryParams, setPreviousGraph2QueryParams] = useState<GraphQueryArgs>()
  const graph1DataRef = useRef<any>()
  const graph2DataRef = useRef<any>()
  const graph1TypeRef = useRef<'line' | 'scatter' | 'bar' | undefined>()
  const graph2TypeRef = useRef<'line' | 'scatter' | 'bar' | undefined>()
  const metric1 = graph1Filters.metric?.value
  const metric2 = graph2Filters.metric?.value

  useEffect(() => {
    if (metric1 || metric2) {
      dispatch(changeChartIsRendering(true))
    }
  }, [
    graph1Filters.metric,
    graph1Filters.selectedEngines,
    graph1Filters.source,
    graph2Filters.metric,
    graph2Filters.selectedEngines,
    graph2Filters.source,
    measuredBy,
    dateFilters,
  ])

  useEffect(() => {
    // This is required for cases when the metric in source 1 was cleared and then the same one selected again
    if (graph1Filters.metric === null || graph1Filters.selectedEngines.length === 0) {
      setPreviousGraph1Filters(graph1Filters)
    }
  }, [graph1Filters])

  useEffect(() => {
    // This is required for cases when the metric in source 2 was cleared and then the same one selected again
    if (graph2Filters.metric === null || graph2Filters.selectedEngines.length === 0) {
      setPreviousGraph2Filters(graph2Filters)
    }
  }, [graph2Filters])

  if (!metric1) {
    graph1DataRef.current = undefined
    graph1TypeRef.current = undefined
  }

  if (!metric2) {
    graph2DataRef.current = undefined
    graph2TypeRef.current = undefined
  }

  if (metric1) {
    if (graph2Filters.metric?.value === 'fuelConsumed') {
      graph1DataRef.current = undefined
      graph1TypeRef.current = undefined
    }
    if (
      !areObjectsEqual(measuredBy, previousMeasuredBy) ||
      !areObjectsEqual(graph1Filters, previousGraph1Filters) ||
      !areObjectsEqual(getQueryParams(graph1Filters), previousGraph1QueryParams)
    ) {
      graph1DataRef.current = undefined
      graph1TypeRef.current = undefined
      dispatch(changeGraphIsFetching({ graphNr: 1, isFetching: true }))
      loadGraphData(graph1Filters, 1, getQueryParams(graph1Filters)).then((result) => {
        if (result) {
          graph1DataRef.current = result
          setPreviousMeasuredBy(measuredBy)
          setPreviousGraph1Filters(graph1Filters)
          setPreviousGraph1QueryParams(getQueryParams(graph1Filters))
        }
        dispatch(changeGraphIsFetching({ graphNr: 1, isFetching: false }))
      })
    }
  }

  if (metric2) {
    if (graph1Filters.metric?.value === 'fuelConsumed') {
      graph2DataRef.current = undefined
      graph2TypeRef.current = undefined
    }
    if (
      !areObjectsEqual(measuredBy, previousMeasuredBy) ||
      !areObjectsEqual(graph2Filters, previousGraph2Filters) ||
      !areObjectsEqual(getQueryParams(graph2Filters), previousGraph2QueryParams)
    ) {
      graph2DataRef.current = undefined
      graph2TypeRef.current = undefined
      dispatch(changeGraphIsFetching({ graphNr: 2, isFetching: true }))
      loadGraphData(graph2Filters, 2, getQueryParams(graph2Filters)).then((result) => {
        if (result) {
          graph2DataRef.current = result
          setPreviousMeasuredBy(measuredBy)
          setPreviousGraph2Filters(graph2Filters)
          setPreviousGraph2QueryParams(getQueryParams(graph2Filters))
        }
        dispatch(changeGraphIsFetching({ graphNr: 2, isFetching: false }))
      })
    }
  }

  function getQueryParams(graphFilters: SourceFilters) {
    const engineData = graphFilters.selectedEngines[0]

    let auxEngineFlowMeters: SplitSensor[] = []
    if (vessel.auxiliary_engines) {
      auxEngineFlowMeters = vessel.auxiliary_engines[0].flowMeter
    }

    const queryParams: GraphQueryArgs = {
      vesselId: vessel.id,
      flowMeterId: engineData.flowMeterId,
      shaftMeterId: engineData.shaftMeterId,
      isSplit: engineData.isSplit,
      mainEngineFlowMeters: vessel.main_engines[0].flowMeter,
      auxEngineFlowMeters: auxEngineFlowMeters,
      allEngineFlowMeters: [...vessel.main_engines[0].flowMeter, ...auxEngineFlowMeters],
      startTime: dateFilters.startTime,
      endTime: dateFilters.endTime,
    }
    return queryParams
  }

  async function loadGraphData(graphFilters: SourceFilters, graphNr: number, queryParams: GraphQueryArgs) {
    if (!graphFilters.selectedEngines.length) return

    const metric = graphFilters.metric?.value

    if (measuredBy === 'time') {
      if (metric === 'fuelConsumed') {
        graphNr === 1 ? (graph1TypeRef.current = 'bar') : (graph2TypeRef.current = 'bar')
        const cachedData = await dispatch(selectTotalFuelConsumedBarChartInRangeByArgs(getQueryParams(graphFilters)))
        if (cachedData) {
          return cachedData
        } else {
          const data = await dispatch(fetchTotalFuelConsumedBarChartInRange(getQueryParams(graphFilters))).unwrap()
          return data
        }
      } else {
        let cachedData
        graphNr === 1 ? (graph1TypeRef.current = 'line') : (graph2TypeRef.current = 'line')
        switch (metric) {
          // Shaft Power
          case 'shaftPower':
            cachedData = await dispatch(selectPowerInRangeInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchPowerInRangeInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // Torque
          case 'torque':
            cachedData = await dispatch(selectTorqueInRangeInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchTorqueInRangeInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // Shaft Speed
          case 'shaftSpeed':
            cachedData = await dispatch(selectRPMInRangeInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchRPMInRangeInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // Mass Flow Rate
          case 'massFlowRate':
            cachedData = await dispatch(selectMassFlowRateInRangeInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchMassFlowRateInRangeInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // Fuel Efficiency
          case 'fuelEfficiency':
            cachedData = await dispatch(selectTotalFuelEfficiencyInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchTotalFuelEfficiencyInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // SFOC
          case 'sfoc':
            cachedData = await dispatch(selectSFOCInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchSFOCInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
          // Speed Over Ground
          case 'sog':
            cachedData = await dispatch(selectSOGInRangeInTimeByArgs(getQueryParams(graphFilters)))
            if (cachedData) {
              return cachedData
            } else {
              const data = await dispatch(fetchSOGInRangeInTime(getQueryParams(graphFilters))).unwrap()
              return data
            }
        }
      }
    }

    if (measuredBy === 'sog') {
      let cachedData
      graphNr === 1 ? (graph1TypeRef.current = 'scatter') : (graph2TypeRef.current = 'scatter')
      switch (metric) {
        // Fuel Efficiency
        case 'fuelEfficiency':
          cachedData = await dispatch(selectFuelEfficiencyInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchFuelEfficiencyInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Shaft Power
        case 'shaftPower':
          cachedData = await dispatch(selectPowerInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchPowerInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Torque
        case 'torque':
          cachedData = await dispatch(selectTorqueInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchTorqueInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Shaft Speed
        case 'shaftSpeed':
          cachedData = await dispatch(selectRPMInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchRPMInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Mass Flow Rate
        case 'massFlowRate':
          cachedData = await dispatch(selectMassFlowRateInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchMassFlowRateInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // SFOC
        case 'sfoc':
          cachedData = await dispatch(selectSFOCInKnotsByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchSFOCInKnots(getQueryParams(graphFilters))).unwrap()
            return data
          }
      }
    }

    if (measuredBy === 'power') {
      let cachedData
      graphNr === 1 ? (graph1TypeRef.current = 'scatter') : (graph2TypeRef.current = 'scatter')
      switch (metric) {
        // Fuel Efficiency
        case 'fuelEfficiency':
          cachedData = await dispatch(selectFuelEfficiencyInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchFuelEfficiencyInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // SFOC
        case 'sfoc':
          cachedData = await dispatch(selectSFOCInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchSFOCInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Torque
        case 'torque':
          cachedData = await dispatch(selectTorqueInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchTorqueInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Shaft Speed
        case 'shaftSpeed':
          cachedData = await dispatch(selectRPMInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchRPMInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Mass Flow Rate
        case 'massFlowRate':
          cachedData = await dispatch(selectMassFlowRateInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchMassFlowRateInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
        // Speed Over Ground
        case 'sog':
          cachedData = await dispatch(selectSOGInPowerByArgs(getQueryParams(graphFilters)))
          if (cachedData) {
            return cachedData
          } else {
            const data = await dispatch(fetchSOGInPower(getQueryParams(graphFilters))).unwrap()
            return data
          }
      }
    }
  }

  return (
    <>
      <AnalyticsChart
        vessel={vessel}
        graphData1={graph1DataRef.current}
        graphData2={graph2DataRef.current}
        graph1Type={graph1TypeRef.current}
        graph2Type={graph2TypeRef.current}
      />
    </>
  )
}

export default AnalyticsChartLoader
