const workerFunction = function () {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (event: MessageEvent) => {
    const { graph1, graph2 } = event.data

    const xlabels: any[] = []
    const timestamps: any[] = []
    let dataset1Values: number[] = []
    let dataset2Values: number[] = []

    const labelSource = graph1?.length ? graph1 : graph2

    for (let i = 0; i < labelSource?.length; i++) {
      const item = labelSource[i]
      const date = new Date(item.startDate._seconds * 1000)
      xlabels.push(date.toUTCString())
    }

    for (let i = 0; i < graph1?.length; i++) {
      const item = graph1[i]
      timestamps.push(item.startDate._seconds)
    }

    for (let i = 0; i < graph1?.length; i++) {
      const item = graph1[i]
      dataset1Values.push(item.value)
    }

    for (let i = 0; i < graph2?.length; i++) {
      const item = graph2[i]
      dataset2Values.push(item.value)
    }

    postMessage({ xlabels, timestamps, dataset1Values, dataset2Values })
  }
}

// This stringifies the whole function
let codeToString = workerFunction.toString()
// This brings out the code in the bracket in string
let mainCode = codeToString.substring(codeToString.indexOf('{') + 1, codeToString.lastIndexOf('}'))
// convert the code into a raw data
let blob = new Blob([mainCode], { type: 'application/javascript' })
// A url is made out of the blob object and we're good to go
let lineChartWorker = URL.createObjectURL(blob)

export default lineChartWorker
