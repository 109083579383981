import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleThunkError } from 'errors/errors'
import { GraphQueryArgs, LineGraphData } from 'interfaces/graphApi'
import { inTimeGraphApi } from 'store/apis/inTimeGraphApi'

export const fetchTorqueInRangeInTime = createAsyncThunk<
  LineGraphData[] | null,
  GraphQueryArgs,
  { rejectValue: string }
>('inTimeGraphData/fetchTorqueInRangeInTime', async (queryParams, { dispatch, rejectWithValue }) => {
  try {
    const rawResult = await dispatch(inTimeGraphApi.endpoints.getTorqueInRangeInTime.initiate(queryParams)).unwrap()
    return rawResult
  } catch (error) {
    console.error('Error fetching data:', error)
    handleThunkError(error)
    return rejectWithValue('Failed to fetch data')
  }
})
