import { useState, useEffect } from 'react'
import { Menu } from '@headlessui/react'
import classNames from 'classnames'
import { ReactComponent as CaretDown } from 'assets/icons/caretDownRegular.svg'
import { ReactComponent as LoadingIcon } from 'assets/images/ship-wheel.svg'

export interface DropdownOption {
  label: string
  value: string
}

interface Props {
  options: DropdownOption[]
  onSelect: (selectedOption: DropdownOption) => void
  width?: string
  iconSrc?: string
  dropdownTitle?: string
  buttonFontWeight?: string
  value?: DropdownOption
  disabled?: boolean
  loading?: boolean
}

function Dropdown({
  options,
  onSelect,
  width = '24',
  iconSrc,
  dropdownTitle,
  buttonFontWeight,
  value,
  disabled,
  loading,
}: Props) {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(null)

  useEffect(() => {
    if (!selectedOption && options.length > 0) {
      if (value) {
        setSelectedOption(value)
      } else {
        setSelectedOption(options[0])
      }
    }
  }, [])

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option)
    onSelect(option)
  }

  return (
    <div
      className={classNames('relative', {
        'opacity-80': disabled,
        'cursor-not-allowed': disabled,
      })}
    >
      <Menu>
        <Menu.Button
          className={`flex flex-row ${width} h-10 text-smoky-steel justify-between border border-grayOutlineColor rounded-lg py-2.5 px-3.5 text-sm lg:text-lg items-center bg-white`}
          disabled={disabled}
          style={{ fontWeight: buttonFontWeight }}
        >
          {iconSrc && (
            <img
              src={iconSrc}
              alt="Icon"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 h-auto w-5"
            />
          )}
          {dropdownTitle && (
            <div className="h-10 flex items-center align-center mr-auto border-grayOutlineColor border-r pr-4">
              {dropdownTitle}
            </div>
          )}
          {loading ? (
            <LoadingIcon className={classNames('w-6 h-6 ml-3 test animate-spin fill-cosmic-black')} />
          ) : (
            <CaretDown className="mt-0.5 ml-3 h-10 w-[0.85rem] fill-smoky-steel" />
          )}
        </Menu.Button>

        <Menu.Items
          className={`absolute ${width} w-full mt-1 z-20 bg-white rounded-md shadow-md border border-grayOutlineColor`}
        >
          {options.map((option, index) => (
            <Menu.Item key={index}>
              <div
                className={classNames(
                  'text-sm lg:text-lg px-3 py-2 cursor-pointer hover:bg-primary border-b border-grayOutlineColor',
                  {
                    'rounded-t-md': index === 0,
                    'rounded-b-md': index === options.length - 1,
                  }
                )}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  )
}

export default Dropdown
