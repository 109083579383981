import classNames from 'classnames'
import { useEffect, useState } from 'react'

export interface ButtonGroupItem {
  label: string
  value: string
  activeButton?: string
  children?: React.ReactNode
}

interface Props {
  buttons: ButtonGroupItem[]
  value: string
  activeButton?: string
  onChange: (value: any) => void
  disabled?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl'
  // Color name from tailwind config
  selectedColorName?: string
}

function ButtonGroup({ buttons, value, activeButton, onChange, disabled, size, selectedColorName }: Props) {
  const [selectedValue, setSelectedValue] = useState(value)
  const [selectedButton, setSelectedButton] = useState(getActiveButton())

  useEffect(() => {
    if (activeButton) setSelectedButton(activeButton)
  }, [activeButton])

  function getActiveButton() {
    return activeButton || value
  }

  function handleButtonClick(value: string) {
    // Special case, can be used to open a dialog without changing the selected status immediately
    // Selected status can be manually assigned later from the value prop
    if (!value.includes('suspend')) {
      setSelectedButton(value)
    }
    setSelectedValue(value)
    onChange(value)
  }

  function isFirstButton(value: string) {
    return value === buttons[0].value
  }

  function isLastButton(value: string) {
    return value === buttons[buttons.length - 1].value
  }

  const buttonCssClasses = classNames('border-y border-grayOutlineColor border-l', {
    'h-10 px-4 text-sm lg:text-lg': !size || size === 'md',
    'h-8 px-3 text-[13px] leading-3': size === 'sm',
    'h-12 px-6 text-xl font-semibold': size === 'xl',
    'cursor-not-allowed': disabled,
    'opacity-50': disabled,
  })

  const buttonsItems = buttons.map((button) => {
    const isSelected = selectedButton === button.value && selectedValue === button.value
    return (
      <button
        key={button.value}
        className={classNames(
          buttonCssClasses,
          {
            'border-r': isLastButton(button.value),
            'rounded-l-lg': isFirstButton(button.value),
            'rounded-r-lg': isLastButton(button.value),
            'bg-primary': isSelected && !selectedColorName,
            'bg-white text-smoky-steel': selectedButton !== button.value && selectedValue !== button.activeButton,
          },
          isSelected && selectedColorName ? `${selectedColorName} text-white` : ''
        )}
        onClick={() => handleButtonClick(button.value)}
        disabled={disabled}
      >
        <div className="flex items-center">
          {button.children && <span className="mr-2">{button.children}</span>}
          {button.label}
        </div>
      </button>
    )
  })

  return <div className="flex items-center">{buttonsItems}</div>
}

export default ButtonGroup
