import { SourceFilters, analyticsState } from '../../../store/slices/analyticsSlice'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  ScatterController,
  Legend,
  BarElement,
  CategoryScale,
} from 'chart.js'
import type { ChartData, ChartOptions, ChartType } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { metricsOptions } from '../data/metricsOptions'
import { VesselData } from 'interfaces/vessel'
import NoData from 'components/utility/NoData'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { changeChartIsRendering, RootState } from 'store'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import scatterChartWorker from 'workers/scatterChartWorker'
import lineChartWorker from 'workers/lineChartWorker'
import { useEffect, useState } from 'react'
import { getLineChartDataset } from 'shared/chart/getLineChartDataset'
import getLineChartScaleX from 'shared/chart/getLineChartScaleX'
import getLineChartScaleY from 'shared/chart/getLineChartScaleY'
import { getLineChartScaleY1 } from 'shared/chart/getLineChartScaleY1'
import getLineChartOptions from 'shared/chart/getLineChartOptions'
import { Scales } from 'interfaces/chart'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Legend,
  ScatterController,
  BarElement,
  CategoryScale
)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  graphData1: any
  graphData2: any
  graph1Type: 'line' | 'scatter' | 'bar' | undefined
  graph2Type: 'line' | 'scatter' | 'bar' | undefined
}

type UnitOfRange = 'hours' | 'days' | 'weeks'

function AnalyticsChart({ vessel, graphData1, graphData2, graph1Type, graph2Type }: Props) {
  const dispatch = useAppDispatch()
  const [scatterData, setScatterData] = useState<any>(null)
  const [lineData, setLineData] = useState<any>(null)
  const { graph1Filters, graph2Filters, dateFilters } = useSelector(analyticsState)

  useEffect(() => {
    setScatterData(null)
    setLineData(null)
  }, [graph1Filters, graph2Filters, dateFilters])

  const {
    graphDataStatus: { graph1IsFetching, graph2IsFetching },
    chartIsRendering,
  } = useAppSelector((state: RootState) => {
    return state.app
  })

  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  const graphType = graph1Type ? graph1Type : graph2Type ? graph2Type : null

  let chartDataAndOptions = null

  useEffect(() => {
    if (graphType !== 'scatter') return

    const worker = new Worker(scatterChartWorker)
    worker.postMessage({ graph1: graphData1, graph2: graphData2 })
    worker.onmessage = (e: MessageEvent) => {
      setScatterData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    // Clean up the worker when the component unmounts
    return () => {
      worker.terminate()
    }
  }, [graphData1, graphData2])

  useEffect(() => {
    if (graphType !== 'line') return

    const worker = new Worker(lineChartWorker)
    worker.postMessage({ graph1: graphData1, graph2: graphData2 })
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      dispatch(changeChartIsRendering(false))
    }
    // Clean up the worker when the component unmounts
    return () => {
      worker.terminate()
    }
  }, [graphData1, graphData2])

  useEffect(() => {
    if (graphType === 'bar') {
      dispatch(changeChartIsRendering(false))
    }
  }, [graphData1, graphData2])

  useEffect(() => {
    if (graphType === null) {
      dispatch(changeChartIsRendering(false))
    }
  }, [graphType])

  if (graphType && (graphData1 || graphData2)) {
    chartDataAndOptions = getChartDataAndOptions(
      graphType,
      graphData1,
      graphData2,
      graph1Filters,
      graph2Filters,
      vesselLastUpdated
    )
  }

  const isLoading = graph1IsFetching || graph2IsFetching || chartIsRendering
  const isGraphReady = graphType && chartDataAndOptions && (graph1Filters.metric || graph2Filters.metric)
  const isDataAvailable = !!chartDataAndOptions?.data?.datasets?.length

  function getChartLabel(options: any, filterData: SourceFilters) {
    const option = options.find(({ value }: any) => value === filterData.metric?.value)
    const selectedEngineLabels = filterData.selectedEngines.map(({ label }) => label)
    return filterData.metric?.value === 'fuelEfficiency'
      ? `${option.label} [${option.description}]`
      : `${selectedEngineLabels.toString()}: ${option.label} [${option.description}]`
  }

  function getBarChartLabel(flowMeterId: string, metricOptions: any, filterData: SourceFilters) {
    const selectedMetric = metricOptions.find(({ value }: any) => value === filterData.metric?.value)
    const engineOption = filterData.selectedEngines.find((item) => item.flowMeterId === parseInt(flowMeterId))
    return `${engineOption?.label}: ${selectedMetric?.label} [${selectedMetric?.description}]`
  }

  function getChartDataAndOptions(
    type: string,
    graph1: any,
    graph2: any,
    graph1Filters: SourceFilters,
    graph2Filters: SourceFilters,
    vesselLastUpdated: number
  ) {
    // Scatter
    if (type === 'scatter') {
      const datasets: any[] = []
      if (graph1?.scatterData?.length && graph1Filters.metric) {
        datasets.push({
          type: 'scatter',
          label: getChartLabel(metricsOptions, graph1Filters),
          data: scatterData?.graph1ScatterData,
          backgroundColor: '#223FA7',
          yAxisID: 'y',
        })
      }
      if (graph2?.scatterData?.length && graph2Filters.metric) {
        datasets.push({
          type: 'scatter',
          label: getChartLabel(metricsOptions, graph2Filters),
          data: scatterData?.graph2ScatterData,
          backgroundColor: '#22A73F',
          yAxisID: 'y1',
        })
      }

      if (graph1?.trendLine?.length && graph1Filters.drawTrendline) {
        datasets.push({
          type: 'line',
          label: 'Trendline',
          data: scatterData?.graph1TrendlineData,
          backgroundColor: '#223FA7',
          borderColor: '#223FA7',
          pointRadius: 0,
          pointHitRadius: 0,
          yAxisID: 'y',
        })
      }
      if (graph2?.trendLine?.length && graph2Filters.drawTrendline) {
        datasets.push({
          type: 'line',
          label: 'Trendline',
          data: scatterData?.graph2TrendlineData,
          backgroundColor: '#22A73F',
          borderColor: '#22A73F',
          pointRadius: 0,
          pointHitRadius: 0,
          yAxisID: 'y1',
        })
      }

      const scales: Scales = {}
      if (graph1?.scatterData && graph1Filters.metric) {
        scales.y = {
          type: 'linear' as const,
          position: 'left' as const,
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph1Filters),
            font: {
              size: 16,
            },
          },
        }
      }
      if (graph2?.scatterData && graph2Filters.metric) {
        scales.y1 = {
          type: 'linear' as const,
          position: 'right' as const,
          title: {
            display: true,
            text: getChartLabel(metricsOptions, graph2Filters),
            font: {
              size: 16,
            },
          },
        }
      }

      return {
        options: {
          animation: false,
          normalized: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              position: 'bottom' as const,
              labels: {
                boxWidth: 12,
                boxHeight: 12,
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: 10,
                font: {
                  size: 16,
                },
              },
            },
          },
          scales,
        } as ChartOptions,
        data: {
          datasets,
        } as ChartData,
      }
    }

    // Bar
    else if (type === 'bar') {
      const graphData = graph1?.length ? graph1 : graph2
      const filterData = graph1?.length ? graph1Filters : graph2Filters
      const selectedEngines = filterData.selectedEngines
      const selectedMeters = selectedEngines.map(({ flowMeterId }) => flowMeterId)
      const unitOfRange: UnitOfRange = graphData?.data?.[0]?.unitOfRange || 'days'

      const selectedDatasets = []
      if (selectedMeters.length) {
        for (let i = 0; i < selectedMeters.length; i++) {
          const meterId = selectedMeters[i]
          const dataset = graphData.find((item: any) => parseInt(item.label) === meterId)
          selectedDatasets.push(dataset)
        }
      }

      return {
        options: {
          maintainAspectRatio: false,
          responsive: true,
          interaction: {
            intersect: false,
            mode: 'index' as const,
          },
          plugins: {
            legend: {
              position: 'bottom' as const,
              labels: {
                boxWidth: 12,
                boxHeight: 12,
                usePointStyle: true,
                pointStyle: 'rectRounded',
                padding: 10,
                font: {
                  size: 16,
                },
              },
            },
            tooltip: {
              usePointStyle: true,
              callbacks: {
                label: function (context: any) {
                  const elementTime = context.raw.x.getTime() / 1000
                  if (elementTime > vesselLastUpdated) {
                    return 'Data not available'
                  } else {
                    return `${context.dataset.label}: ${context.raw.y}`
                  }
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
              title: {
                display: true,
                text: graphData?.[0]?.unitOfMeasurement,
                font: {
                  size: 16,
                },
              },
            },
          },
          elements: {
            bar: {
              borderRadius: 4,
            },
          },
        } as ChartOptions,
        data: {
          labels:
            selectedDatasets[0]?.data.map((item: any) => {
              const timestamp = parseInt(item.category)
              const dayString = getUTCDateString(new Date(timestamp))
              if (unitOfRange === 'hours') {
                const timeString = getUTCTimeString(new Date(timestamp))
                return `${dayString} ${timeString}`
              } else if (unitOfRange === 'weeks') {
                const lastDayOfWeek = new Date(timestamp)
                lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)
                const lastDayString = getUTCDateString(lastDayOfWeek)
                return `${dayString} - ${lastDayString}`
              } else {
                return dayString
              }
            }) || [],
          datasets: [
            ...(selectedDatasets?.map((dataset: any) => ({
              label: getBarChartLabel(dataset.label, metricsOptions, filterData),
              data: dataset.data.map((item: any) => ({ x: new Date(parseInt(item.category)), y: item.value })),
              backgroundColor: getBarChartLabel(dataset.label, metricsOptions, filterData).includes('M/E')
                ? '#223FA7'
                : '#2EA5DB',
              maxBarThickness: 100,
            })) || []),
          ],
        },
      }
    }

    // Line chart
    else {
      if (!lineData) return
      const { xlabels, timestamps, dataset1Values, dataset2Values } = lineData

      const datasets: any = []
      if (dataset1Values?.length && graph1Filters.metric) {
        const chartLabel = getChartLabel(metricsOptions, graph1Filters)
        datasets.push(getLineChartDataset(chartLabel, dataset1Values, timestamps, vesselLastUpdated, '#223FA7', 'y'))
      }
      if (dataset2Values?.length && graph2Filters.metric) {
        const chartLabel = getChartLabel(metricsOptions, graph2Filters)
        datasets.push(getLineChartDataset(chartLabel, dataset2Values, timestamps, vesselLastUpdated, '#12B76A', 'y1'))
      }

      const scales: Scales = {}
      if (xlabels?.length) {
        scales.x = getLineChartScaleX(xlabels)
        if (graph1?.length && graph1Filters.metric) {
          const chartLabel = getChartLabel(metricsOptions, graph1Filters)
          scales.y = getLineChartScaleY(chartLabel)
        }
        if (graph2?.length && graph2Filters.metric) {
          const chartLabel = getChartLabel(metricsOptions, graph2Filters)
          scales.y1 = getLineChartScaleY1(chartLabel)
        }
      }

      return {
        options: getLineChartOptions(scales, timestamps, vesselLastUpdated) as any,
        data: {
          labels: Array.from({ length: dataset1Values.length || dataset2Values.length }, () => ''),
          datasets,
        } as ChartData,
      }
    }
  }

  return (
    <>
      {chartDataAndOptions && isGraphReady && isDataAvailable && !isLoading && (
        <Chart
          options={chartDataAndOptions.options}
          type={graphType as ChartType}
          data={chartDataAndOptions.data}
        ></Chart>
      )}
      {!isGraphReady && !isLoading && (
        <div className="pt-32">
          <NoData
            type="warning"
            title="No data available"
            text="Please, select data source."
          />
        </div>
      )}
      {!isDataAvailable && isGraphReady && !isLoading && (
        <div className="pt-32">
          <NoData
            type="warning"
            title="No data available for selected period"
            text="Please, try different date range."
          />
        </div>
      )}
    </>
  )
}

export default AnalyticsChart
