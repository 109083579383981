import { VesselData } from 'interfaces/vessel'
import Loader from 'components/Loader'
import AnalyticsChartLoader from './AnalyticsChartLoader'
import { RootState } from 'store'
import { useAppSelector } from 'hooks/useReduxHooks'

interface Props {
  vessel: VesselData | null
  vesselFetching: boolean
}

function LoadingScreen({ vesselFetching }: { vesselFetching: boolean }) {
  const {
    graphDataStatus: { graph1IsFetching, graph2IsFetching },
    chartIsRendering,
  } = useAppSelector((state: RootState) => {
    return state.app
  })

  const isLoading = graph1IsFetching || graph2IsFetching || vesselFetching || chartIsRendering

  return (
    <>
      {isLoading && (
        <div
          className="absolute top-0 left-0 z-10 w-full h-full flex justify-center items-center bg-slate-100 opacity-80"
          style={{ backgroundColor: 'white' }}
        >
          <Loader />
        </div>
      )}
    </>
  )
}

function AnalyticsChartWrapper({ vessel, vesselFetching }: Props) {
  return (
    <div className="min-w-32 relative min-h-[20rem] min-height-900:min-h-[25rem] min-height-1000:min-h-[32rem] min-height-1100:min-h-[38rem] min-height-1200:min-h-[44rem] min-height-1300:min-h-[52rem] min-height-1400:min-h-[60rem]">
      {vessel && <AnalyticsChartLoader vessel={vessel} />}
      <LoadingScreen vesselFetching={vesselFetching} />
    </div>
  )
}

export default AnalyticsChartWrapper
