import { createSlice } from '@reduxjs/toolkit'
import { RootState, login, logout, validateAndSetNewPassword, getUserCustomClaims } from '..'
import { AuthUser } from 'interfaces/auth'
import { handleError } from 'errors/errors'
import { isUserExpired } from 'shared/date/isUserExpired'

interface AuthState {
  user: AuthUser | null
  authIsLoading: boolean
  initiallyRequestedPath: string
  authError: null | string
  passwordChanged: boolean
  authSuccessful: boolean
}

const initialState: AuthState = {
  user: null,
  authIsLoading: true,
  initiallyRequestedPath: '',
  authError: null,
  passwordChanged: false,
  authSuccessful: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthIsLoading(state, action) {
      state.authIsLoading = action.payload
    },
    changeAuthError(state, action) {
      state.authError = action.payload
    },
    changeInitiallyRequestedUrl(state, action) {
      const url = action.payload
      if (!url.includes('login')) {
        state.initiallyRequestedPath = url
      }
    },
    changeUser(state, action) {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.authError = null
    })
    builder.addCase(login.rejected, (state, action) => {
      state.authIsLoading = false
      if (action.error.message) {
        state.authError = handleError(action.error.message)
      }
    })

    builder.addCase(logout.fulfilled, (state) => {
      state.user = null
      state.authIsLoading = false
      state.authSuccessful = false
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.authIsLoading = false
      if (action.error.message) {
        state.authError = handleError(action.error.message)
      }
    })

    builder.addCase(validateAndSetNewPassword.fulfilled, (state) => {
      state.passwordChanged = true
      state.authIsLoading = false
    })
    builder.addCase(validateAndSetNewPassword.rejected, (state, action) => {
      state.authIsLoading = false
      if (action.error.message) {
        state.authError = handleError(action.error.message)
      }
    })

    builder.addCase(getUserCustomClaims.fulfilled, (state, action) => {
      if (!state.user) {
        state.authIsLoading = false
        return
      }
      if (action.payload.customClaims?.company) {
        state.user.companyId = action.payload.customClaims.company
      }
      if (action.payload.customClaims?.admin === true) {
        state.user.isAdmin = true
      }
      if (action.payload.customClaims?.demo === true) {
        state.user.isDemo = true
      }
      if (action.payload.customClaims?.validUntil) {
        const validUntil = action.payload.customClaims.validUntil
        if (!isUserExpired(validUntil)) {
          state.authSuccessful = true
        }
        state.user.validUntil = action.payload.customClaims.validUntil
      } else {
        state.authSuccessful = true
      }
      state.authIsLoading = false
    })
    builder.addCase(getUserCustomClaims.rejected, (state, action) => {
      state.authIsLoading = false
      if (action.error.message) {
        state.authError = handleError(action.error.message)
      }
    })

    builder.addMatcher(
      (action) => {
        return action.type.startsWith('auth/') && action.type.endsWith('/pending')
      },
      (state) => {
        state.authIsLoading = true
      }
    )
  },
})

export const authState = (state: RootState) => state.auth
export const { changeAuthIsLoading, changeInitiallyRequestedUrl, changeUser, changeAuthError } = authSlice.actions
export const authReducer = authSlice.reducer
