export function getLineChartDataset(
  chartLabel: string,
  datasetValues: any,
  timestamps: any,
  vesselLastUpdated: number,
  color: string,
  yAxisID: string
) {
  return {
    spanGaps: true,
    pointRadius: 0,
    type: 'line',
    label: chartLabel,
    data: datasetValues,
    backgroundColor: color,
    borderColor: color,
    borderWidth: 2,
    segment: {
      borderColor: (context: any) => {
        const segmentTimestamp = timestamps[context.p1DataIndex]
        return segmentTimestamp > vesselLastUpdated ? 'transparent' : color
      },
    },
    tension: 0.2,
    yAxisID,
    pointStyle: 'rectRounded',
  }
}
