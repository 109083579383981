import { RootState } from 'store'
import { useAppSelector } from 'hooks/useReduxHooks'
import VesselInfo from 'components/VesselInfo'
import ShaftSpeedAndPower from './components/ShaftSpeedAndPower'
import FuelConsumption from './components/FuelConsumption'
import Speed from './components/Speed'
import FuelConsumptionVsSpeed from './components/FuelConsumptionVsSpeed'

function Analytics() {
  const {
    dateRange: { startTime, endTime },
    selectedVessel,
    vesselIsFetching,
  } = useAppSelector((state: RootState) => {
    return state.performance
  })

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="mt-4">
        {selectedVessel && (
          <div className="mb-8">
            <ShaftSpeedAndPower
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumption
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <Speed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}

        {selectedVessel && (
          <div className="mb-8">
            <FuelConsumptionVsSpeed
              vessel={selectedVessel || null}
              startTime={startTime}
              endTime={endTime}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Analytics
