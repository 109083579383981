import { analyticsState, changeDateFilters } from '../../../store/slices/analyticsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { changeChartIsRendering, RootState } from 'store'
import { getCustomDateTimeStamps } from 'shared/date/getCustomDateTimeStamps'
import getTimePeriodData from 'shared/date/getTimePeriodData'
import DatePickerLinked from 'components/form/DatePickerLinked'

function DateFilters() {
  const datepickerContainer = useRef<HTMLInputElement>(null)
  const {
    dateFilters: { selectedButton },
    graph1IsFetching,
    graph2IsFetching,
  } = useSelector(analyticsState)
  const dispatch = useAppDispatch()
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
  const {
    app: {
      selectedTimezone: { offset },
    },
    auth: { user },
  } = useAppSelector((state: RootState) => {
    return state
  })

  const keepDatePickerAlive = selectedButton === 'custom' || showDatePicker
  const loading = graph1IsFetching || graph2IsFetching

  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (!datepickerContainer.current) return
      if (!datepickerContainer.current.contains(event.target as any)) {
        setShowDatePicker(false)
      }
    }

    document.addEventListener('click', handler, true)

    return () => {
      document.removeEventListener('click', handler, true)
    }
  }, [])

  function handlePeriodButtonClick(period: string) {
    dispatch(changeChartIsRendering(true))
    const data = getTimePeriodData(period)
    dispatch(changeDateFilters(data))
    setShowDatePicker(false)
  }

  useEffect(() => {
    const data = getTimePeriodData('sevenDays')
    dispatch(changeDateFilters(data))
  }, [])

  useEffect(() => {
    if (user?.isDemo) {
      const data = getTimePeriodData('demoTime')
      dispatch(changeDateFilters(data))
    } else {
      const data = getTimePeriodData('sevenDays')
      dispatch(changeDateFilters(data))
    }
  }, [user])

  function handleCustomDateClick() {
    setShowDatePicker(true)
  }

  function applyCustomDateRange({ startTime, endTime }: { startTime: Date | null; endTime: Date | null }) {
    setShowDatePicker(false)
    if (startTime && endTime) {
      const selectedPeriodData = getCustomDateTimeStamps(startTime, endTime, offset)
      dispatch(
        changeDateFilters({
          selectedButton: 'custom',
          startTime: selectedPeriodData.startTime,
          endTime: selectedPeriodData.endTime,
        })
      )
    }
  }

  function hideDatePicker() {
    setShowDatePicker(false)
  }

  const buttonCssClasses = classNames('h-8 px-3 text-[13px] leading-3 border-grayOutlineColor', {
    'cursor-not-allowed': loading || user?.isDemo,
    'opacity-50': loading || user?.isDemo,
  })

  return (
    <div className="flex mb-4 relative">
      <button
        className={`${buttonCssClasses} border rounded-l-md ${selectedButton === 'custom' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={handleCustomDateClick}
        disabled={loading || user?.isDemo}
      >
        <div className="flex items-center">
          <CalendarIcon
            className="mr-[0.4rem] ml-[0.2rem] mb-[0.1rem]"
            fill={`${selectedButton === 'custom' ? '#292929' : '#5D657A'}`}
          />
          <div>Custom</div>
        </div>
      </button>
      <button
        className={`${buttonCssClasses} border-y border-r ${selectedButton === 'today' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={() => handlePeriodButtonClick('today')}
        disabled={loading || user?.isDemo}
      >
        Today
      </button>
      <button
        className={`${buttonCssClasses} border-y border-r ${selectedButton === 'sevenDays' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={() => handlePeriodButtonClick('sevenDays')}
        disabled={loading || user?.isDemo}
      >
        Last 7 days
      </button>
      <button
        className={`${buttonCssClasses} border-y border-r ${selectedButton === 'fourteenDays' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={() => handlePeriodButtonClick('fourteenDays')}
        disabled={loading || user?.isDemo}
      >
        Last 14 days
      </button>
      <button
        className={`${buttonCssClasses} border-y ${selectedButton === 'month' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={() => handlePeriodButtonClick('month')}
        disabled={loading || user?.isDemo}
      >
        Last month
      </button>
      <button
        className={`${buttonCssClasses} border rounded-r-md ${selectedButton === 'year' ? 'bg-primary' : 'bg-white text-gray'}`}
        onClick={() => handlePeriodButtonClick('year')}
        disabled={loading || user?.isDemo}
      >
        Last year
      </button>
      {keepDatePickerAlive && (
        <div
          ref={datepickerContainer}
          className={classNames('absolute translate-y-9 z-20', { hidden: !showDatePicker })}
        >
          <DatePickerLinked
            onApply={applyCustomDateRange}
            onCancel={hideDatePicker}
          />
        </div>
      )}
    </div>
  )
}

export default DateFilters
