import classNames from 'classnames'

interface Props {
  percentage?: number
  isReversed?: boolean
}

const Percentage = ({ percentage = 0, isReversed = false }: Props) => {
  let statusColor

  if (isReversed) {
    statusColor = percentage > 0 ? 'bad' : 'good'
  } else {
    statusColor = percentage < 0 ? 'bad' : 'good'
  }

  return (
    <div>
      <div className="flex pl-2 gap-1">
        <p className={classNames('', statusColor === 'bad' ? 'text-coral-blaze' : 'text-lush-meadow')}>{percentage}%</p>
      </div>
    </div>
  )
}

export default Percentage
