import { ThunkAction } from '@reduxjs/toolkit'
import { GraphQueryArgs, ScatterGraphData } from 'interfaces/graphApi'
import { RootState } from 'store'
import { inKnotsGraphApi } from 'store/apis/inKnotsGraphApi'

export const selectRPMInKnotsByArgs = (
  args: GraphQueryArgs
): ThunkAction<ScatterGraphData | undefined, RootState, unknown, any> => {
  return (dispatch: any, getState: () => RootState) => {
    const state = getState()
    const queryState = inKnotsGraphApi.endpoints.getRPMInKnots.select(args)(state)
    const data: ScatterGraphData | undefined = queryState.data
    return data
  }
}
