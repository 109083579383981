import { createAsyncThunk } from '@reduxjs/toolkit'
import { handleThunkError } from 'errors/errors'
import { BarChart, GraphQueryArgs } from 'interfaces/graphApi'
import { totalsGraphApi } from 'store/apis/totalsGraphApi'

export const fetchTotalFuelConsumedBarChartInRange = createAsyncThunk<
  BarChart[] | null,
  GraphQueryArgs,
  { rejectValue: string }
>('totalsGraphData/fetchTotalFuelConsumedBarChartInRange', async (queryParams, { dispatch, rejectWithValue }) => {
  try {
    const rawResult = await dispatch(
      totalsGraphApi.endpoints.getTotalFuelConsumedBarChartInRange.initiate(queryParams)
    ).unwrap()
    return rawResult
  } catch (error) {
    console.error('Error fetching data:', error)
    handleThunkError(error)
    return rejectWithValue('Failed to fetch data')
  }
})
