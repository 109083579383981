import FiltersPanel from './components/FiltersPanel'
import SidePanel from '../../components/layout/SidePanel'
import PageWrapper from '../../components/layout/PageWrapper'
import ContentWrapper from '../../components/layout/ContentWrapper'
import VesselInfo from 'components/VesselInfo'
import DateFilters from './components/DateFilters'
import { useParams } from 'react-router-dom'
import { useGetVesselByIdQuery } from 'store'
import AnalyticsHeader from './components/AnalyticsHeader'
import AnalyticsChartWrapper from './components/AnalyticsChartWrapper'
import isSmallScreen from 'shared/dimensions/isSmallScreen'

function DataAnalytics() {
  const { vesselId } = useParams()
  const { data: vessel, isFetching } = useGetVesselByIdQuery(vesselId || '', { skip: !vesselId })

  return (
    <div className="flex flex-col min-w-full">
      <>
        <AnalyticsHeader vessel={vessel || null} />
        <div className="analytics-content flex flex-col-reverse lg:flex-row">
          <SidePanel
            className="lg:h-screen max-lg:border-t lg:border-r"
            maxHeight={isSmallScreen() ? '' : 'calc(100vh - 9.813rem)'}
          >
            <FiltersPanel
              vessel={vessel || null}
              vesselLoading={isFetching}
            />
          </SidePanel>
          <PageWrapper maxHeight={isSmallScreen() ? '' : 'calc(100vh - 9.813rem)'}>
            <ContentWrapper>
              <VesselInfo
                vessel={vessel || null}
                isLoading={isFetching}
              />
              <div className="bg-white rounded-xl flex-grow overflow-auto mt-4 p-4 border border-grayOutlineColor">
                <DateFilters />
                <AnalyticsChartWrapper
                  vessel={vessel || null}
                  vesselFetching={isFetching}
                />
              </div>
            </ContentWrapper>
          </PageWrapper>
        </div>
      </>
    </div>
  )
}

export default DataAnalytics
