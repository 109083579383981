const workerFunction = function () {
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = (event: MessageEvent) => {
    const { graph1, graph2 } = event.data

    const graph1ScatterData = graph1?.scatterData.map((item: any) => ({
      x: item.valueX ?? null,
      y: item.valueY ?? null,
    }))

    const graph2ScatterData = graph2?.scatterData.map((item: any) => ({
      x: item.valueX ?? null,
      y: item.valueY ?? null,
    }))

    const graph1TrendlineData = graph1?.trendLine.map((item: any) => ({
      x: item.valueX ?? null,
      y: item.valueY ?? null,
    }))

    const graph2TrendlineData = graph2?.trendLine.map((item: any) => ({
      x: item.valueX ?? null,
      y: item.valueY ?? null,
    }))

    postMessage({ graph1ScatterData, graph2ScatterData, graph1TrendlineData, graph2TrendlineData })
  }
}

//This stringifies the whole function
let codeToString = workerFunction.toString()
//This brings out the code in the bracket in string
let mainCode = codeToString.substring(codeToString.indexOf('{') + 1, codeToString.lastIndexOf('}'))
//convert the code into a raw data
let blob = new Blob([mainCode], { type: 'application/javascript' })
//A url is made out of the blob object and we're good to go
let scatterChartWorker = URL.createObjectURL(blob)

export default scatterChartWorker
