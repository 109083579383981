export function getLineChartScaleY1(chartLabel: string) {
  return {
    beginAtZero: true,
    position: 'right',
    ticks: {
      count: 7,
      display: true,
    },
    title: {
      display: true,
      text: chartLabel,
      font: {
        size: 16,
      },
    },
  }
}
