import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react'
import { functions } from '../../firebase'
import { httpsCallable } from 'firebase/functions'
import { BarChart, GraphQueryArgs, SingleDataMetrics, ValuePercentage } from 'interfaces/graphApi'

const totalsGraphApi = createApi({
  reducerPath: 'totalsGraphApi',
  baseQuery: fakeBaseQuery(),
  keepUnusedDataFor: 600,
  endpoints(build) {
    return {
      getAverageSFOCInRange: build.query<ValuePercentage, GraphQueryArgs>({
        queryFn: async ({ vesselId, mainEngineFlowMeter, shaftMeterId, startTime, endTime }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getAverageSFOCInRange')
            const result: any = await getData({ vesselId, mainEngineFlowMeter, shaftMeterId, startTime, endTime })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getAverageFuelEfficiencyInRange: build.query<ValuePercentage, GraphQueryArgs>({
        queryFn: async ({ vesselId, allEngineFlowMeters, startTime, endTime, isSplit }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getAverageFuelEfficiencyInRange')
            const result: any = await getData({ vesselId, allEngineFlowMeters, startTime, endTime, isSplit })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getSingleDataMetricsWithChangeInRange: build.query<SingleDataMetrics[], GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeter,
          allEngineFlowMeters,
          shaftMeterId,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getSingleDataMetricsWithChangeInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeter,
              allEngineFlowMeters,
              shaftMeterId,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getTotalFuelConsumedBarChartInRange: build.query<BarChart[], GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeters,
          auxEngineFlowMeters,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getTotalFuelConsumedBarChartInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeters,
              auxEngineFlowMeters,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
      getCO2EmissionsBarChartInRange: build.query<BarChart, GraphQueryArgs>({
        queryFn: async ({
          vesselId,
          mainEngineFlowMeters,
          auxEngineFlowMeters,
          startTime,
          endTime,
        }: GraphQueryArgs) => {
          try {
            const getData = httpsCallable(functions, 'getCO2EmissionsBarChartInRange')
            const result: any = await getData({
              vesselId,
              mainEngineFlowMeters,
              auxEngineFlowMeters,
              startTime,
              endTime,
            })
            return { data: result.data[0] }
          } catch (error) {
            return { error }
          }
        },
      }),
    }
  },
})

export const {
  useGetAverageSFOCInRangeQuery,
  useGetAverageFuelEfficiencyInRangeQuery,
  useGetSingleDataMetricsWithChangeInRangeQuery,
  useGetTotalFuelConsumedBarChartInRangeQuery,
  useGetCO2EmissionsBarChartInRangeQuery,
} = totalsGraphApi

export { totalsGraphApi }
